// Skills icons - https://icon-sets.iconify.design/
import { Icon } from "@iconify/react";

// Navbar Logo image (uncomment below and import your image)
// import newLogo from "./images/yourFileName"

// Projects Images (add your images to the images directory and import below)
import RedditLogo from "./images/reddit.jpg";
import ReactLogo from "./images/react.jpg";
import SpotifyLogo from "./images/spotify.jpg";
import NavLogo from "./images/nav.png";

// Hero Images (add your images to the /images directory with the same names)
import HeroLight from "./images/hero-light.jpg";
import HeroDark from "./images/hero-dark.jpg";
// If you change the import names above then you need to change the export names below
export { HeroLight as Light };
export { HeroDark as Dark };

/* START HERE
 **************************************************************
  Add your GitHub username (string - "YourUsername") below.
*/
export const githubUsername = "AkshatMishra99";

// Navbar Logo image
export const navLogo = NavLogo;

// Blog link icon - https://icon-sets.iconify.design/
export const Blog = <Icon icon="ph:link-bold" />;

/* About Me
 **************************************************************
  Add a second paragraph for the about me section.
*/
export const moreInfo =
	"I engineer robust backend solutions with NodeJS and craft captivating MERN stack applications to empower businesses and individuals with seamless digital experiences. 🛠️✨";

/* Skills
 ************************************************************** 
  Add or remove skills in the SAME format below, choose icons here - https://icon-sets.iconify.design/
*/
export const skillData = [
	{
		id: 1,
		skill: <Icon icon="teenyicons:nodejs-solid" className="display-4" />,
		name: "NodeJS"
	},
	{
		id: 2,
		skill: <Icon icon="file-icons:nestjs" className="display-4" />,
		name: "NestJS"
	},
	{
		id: 3,
		skill: <Icon icon="mdi:react" className="display-4" />,
		name: "ReactJS"
	},
	{
		id: 4,
		skill: <Icon icon="simple-icons:typescript" className="display-4" />,
		name: "TypeScript"
	},
	{
		id: 5,
		skill: <Icon icon="fa6-brands:js" className="display-4" />,
		name: "JavaScript"
	},
	{
		id: 6,
		skill: <Icon icon="teenyicons:docker-solid" className="display-4" />,
		name: "Docker"
	},
	{
		id: 7,
		skill: <Icon icon="cib:postgresql" className="display-4" />,
		name: "PostgreSQL"
	},
	{
		id: 8,
		skill: <Icon icon="simple-icons:mongodb" className="display-4" />,
		name: "MongoDB"
	},
	{
		id: 9,
		skill: <Icon icon="fa6-brands:aws" className="display-4" />,
		name: "AWS"
	}
];

export const resume =
	"https://drive.google.com/file/d/1PLTopRMnWgecPcMLoKs0rJhg5umvTSDy/view?usp=sharing";

export const filteredProjects = [
	"RedditWithNextJS",
	"spotify2.0",
	"savanna-react"
];

// Replace the defualt GitHub image for matching repos below (images imported above - lines 7-8)
export const projectCardImages = [
	{
		name: "RedditWithNextJS",
		image: RedditLogo
	},
	{
		name: "savanna-react",
		image: ReactLogo
	},
	{
		name: "spotify2.0",
		image: SpotifyLogo
	}
];

/* Contact Info
 ************************************************************** 
  Add your formspree endpoint below.
  https://formspree.io/
*/
export const formspreeUrl = "https://formspree.io/f/xpzvbzak";
